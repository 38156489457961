<template>
  <div :class="`absolute z-50 right-32 md:left-1/2 md:-translate-x-1/2 flex-1 ${isFocused ? 'w-full top-0 left-0 md:h-auto md:w-[348px] md:top-[initial] md:left-1/2' : 'w-11 md:w-[348px]'}`">
    <Combobox v-model="selected" v-slot="{ open }">
      <label class="relative block md:rounded-2xl">
        <span class="sr-only">Search</span>
        <ComboboxInput
          id="searchInput"
          type="text"
          name="search"
          autocomplete="off"
          placeholder="Search"
          :class="[
            'peer text-ellipsis placeholder:text-secondaryText block w-full md:rounded-2xl md:shadow-search py-2 pl-12 pr-3 md:bg-white outline-none',
            `${!isFocused ? 'pl-0 w-8 bg-transparent' : 'h-16 md:!h-auto shadow-lg'}`,
            `${open ? '!rounded-bl-none !rounded-br-none' : ''}`,
            `${selected ? '!pr-10' : ''}`,
          ]"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @change="input = $event.target.value"
          :displayValue="(procedure) => procedure?.name"
        />
        <svg
          :class="[
            'absolute top-1/2 -translate-y-1/2 left-4 flex items-center h-6 md:h-5 fill-primaryText md:fill-secondaryText peer-focus:fill-brand',
          ]"
          viewBox="0 0 20 20"
        >
          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
        </svg>
        <button
          v-show="input || selected"
          :class="[
            'absolute top-1/2 -translate-y-1/2 right-4 flex items-center h-5 w-5 fill-secondaryText peer-focus:fill-primaryText',
            `${!isFocused ? 'hidden md:block' : 'block'}`,
          ]"
          @click="clearSearch"
        >
          <svg  viewBox="0 -960 960 960">
            <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/>
          </svg>
        </button>
      </label>
      <div class="relative">
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          @after-leave="input = ''"
        >
          <ComboboxOptions class="absolute max-h-60 w-full overflow-auto rounded-b-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div
              v-if="result.length < 1"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              Nothing found.
            </div>

            <ComboboxOption
              v-for="(procedure) in result"
              as="template"
              :key="procedure.id"
              :value="procedure"
              v-slot="{ selected, active }"
            >
              <li
                class="relative cursor-default select-none py-2 px-4"
                :class="{
                  'bg-brand text-white': active,
                  'text-primaryText': !active,
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ procedure.name }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                  :class="{ 'text-white': active, 'text-brand': !active }"
                >
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import { refDebounced } from '@vueuse/core'

const { $api } = useNuxtApp()
const input = ref('')
const debouncedSearch = refDebounced(input, 300)
const requestInput = computed(() => debouncedSearch.value.length > 1 ? debouncedSearch.value : '')
let searchInput = undefined
const selected = ref(null)
const isFocused = ref(false)
const result = ref([])
// const { data: result } = await useFetch(() => `/api/search/?search=${requestInput.value}`)

onMounted(() => {
  searchInput = document.getElementById('searchInput')
})

function clearSearch() {
  input.value = ''
  selected.value = null
  searchInput.focus()
}

const { filterQuery } = useFilter()
watch(selected, async (val) => {
  if (val?.slug) await navigateTo(`/search-results/${val.slug}/${filterQuery.value}`)
})
watch(debouncedSearch, async (val) => {
  console.log("🚀 ~ watch ~ val:", val)
  try {
    const data = await $api(`/api/search/?search=${val}`)
    result.value = data
    console.log("🚀 ~ watch ~ data:", data)
  } catch (error) {
    console.log("🚀 ~ watch ~ error:", error)
  }
})

</script>
